
/**
 * Player file with session creation.
 */
import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useWindowDimensions from '../../../hooks/useDimensions'
import { Backgeound, Logo, Slider } from '../../../components';
import MusicPlayer from '../../../components/MusicPlayer';
import { Typography, Grid, Box } from '@material-ui/core';
import { useHistory } from "react-router";
import MenuIcon from '@mui/icons-material/Menu';
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined';
import PauseIcon from '@mui/icons-material/Pause';
import { useSnackbar } from "react-simple-snackbar";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import SubjectIcon from '@mui/icons-material/Subject';
import IconButton from '@mui/material/IconButton';
import SpotifyPlayer from 'react-spotify-web-playback';
import Image from '../../../assets/images/background.png';
import ProgressBar from "@ramonak/react-progress-bar";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import axios from "axios";
import { useLocation } from 'react-router-dom'
// import SpotifyPlayer from 'react-spotify-player';
import Drawer from './drawer'
import Model from './model'
import './login.css';

import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LoopIcon from '@mui/icons-material/Loop';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import FirstModel from './firstModel'
import MoodModel from './moodModel'


const axiosInstance = axios.create({
    baseURL: "https://api-beta.feelyoumusic.com",
    headers: {
        Accept: "application/json",
    },
});
const CLIENT_ID = '4731d7bd7737454cad87c9cdc1e4038f'
const REDIRECT_URI = "http://localhost:3000/music" //'https://app.feelyoumusic.com/music'
const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize"
const TRACK_API = '/track'
const PHRASE_URL = `/phrase/getPharseByMoodId?moodId=`;
const MOOD_STATUS_API = 'session/getpointsBySessionId?sessionId='
const IMAGEURL = '/backgrounds'
const RESPONSE_TYPE = "token"
const size = {
    width: '100%',
    height: 80,
};
const view = 'list'; // or 'coverart'
const theme = 'black'; // or 'white'

export const HomeScreen = () => {
    const classes = useStyles()
    const playerRef = useRef(null)
    const { height } = useWindowDimensions();
    const location = useLocation()
    const [openSnackbar] = useSnackbar();
    const history = useHistory();
    const [bgImage, setBgImage] = useState('');
    const [bgImages, setBgImages] = useState('');
    const [moodList, setMoodList] = useState([]);
    const [slides, setSlides] = useState([]);
    const [selectedMood, setSelectedMood] = useState(null);
    const [token, setToken] = useState("")
    const [loading, setLoading] = useState(false);
    const [isPlay, setIsPlay] = useState(true);
    const [tracks, setTracks] = useState([])
    const [selectedTrack, setSelectedTrack] = useState('')
    const [isTrack, setIsTack] = useState(false)
    const [playedList, setPlayedList] = useState([])
    const [trackDetails, setTrackDetails] = useState(null)
    const [iterationNo, setIterationNo] = useState('')
    const [iterationId, setIterationId] = useState('')
    const [sessionId, setSessionId] = useState('')
    const [open, setOpen] = React.useState(false);
    const [openFirst, setOpenFirst] = React.useState(false);
    const [openMood, setOpenMood] = React.useState(false);
    const [moodStatus, setMoodStatus] = React.useState(0);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const firsthandleOpen = () => setOpenFirst(true);
    const firsthandleClose = () => setOpenFirst(false);
    const moodhandleOpen = () => setOpenMood(true);
    const moodhandleClose = () => setOpenMood(false);
    const [selectedSlider, setSelectedSlider] = useState(0);
    const [slideLength, setSlideLength] = useState(0);


    const [myPlaylist, setMyPlaylist] = useState([]);
    const [currentMusicIndex, setCurrentMusicIndex] = useState(0);
    const [tracksIds, setTracksIds] = useState('')
    const [singleTrack, setSingleTrack] = useState(false);
    const [iterationComplete, setiterationComplete] = useState(false);
    const [currentTime, setCurrentTime] = useState('');
    const userData = localStorage.getItem("user");
    const user = JSON.parse(userData);
    const [like, setLike] = useState(false);
    const [repeat, setRepeat] = useState(false);
    const noOfTrack = 3;
    const setLikeFn = () => {
        setLike(!like)
    }

    const setRepeatFn = () => {
        setRepeat(!repeat)
    }
    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }
    const destructMS = (milli) => {
        if (isNaN(milli) || milli < 0) {
            return null;
        }

        var d, h, m, s, ms;
        s = Math.floor(milli / 1000);
        m = Math.floor(s / 60);
        s = s % 60;
        h = Math.floor(m / 60);
        m = m % 60;
        d = Math.floor(h / 24);
        h = h % 24;
        ms = Math.floor((milli % 1000) * 1000) / 1000;

        return padTo2Digits(h) + ":" + padTo2Digits(m) + ":" + padTo2Digits(s);
    }
    
   
    useEffect(() => {
        if (tracks.length) {
            let trackIds = [];
            tracks.map((item) => {
                trackIds.push(item.spotifyTrackId)
            });
            getSpotifyTracks(trackIds).then(res => {
                let trackDs = [];
                tracks.map((tr) => {
                    res.map((item) => {
                        if (tr.spotifyTrackId == item.id || tr.trackName.toLowerCase() === item.name.toLowerCase()) {
                            trackDs.push({ name: item.name, src: tr.previewURL, artists: item.artists, image: item.album.images[2].url, id: tr.id });
                        }
                    })

                })
                setMyPlaylist(trackDs)
                setSelectedTrack(myPlaylist[0])
            })
        }
    }, [tracks])


    const getFeedBack = async (type) => {
        try {
            const URL = "/iterationAnalytics/trackFeedback";
            const request = {
                "iterationId": iterationId,
                "trackId": myPlaylist[currentMusicIndex].id,
                "trackFeedback": type,
                "timePlayed": destructMS(currentTime)
            }
            const response = await axiosInstance.put(URL, request, {
                headers: {
                    Authorization: "Bearer " + user.jwtToken,
                },
            });
        } catch (error) {
            console.log("error", error)

        }
    }
    const handleClickPrevious = (e) => {
        if (currentMusicIndex !== 0) {
            setCurrentMusicIndex(currentMusicIndex === 0 ? myPlaylist.length - 1 : currentMusicIndex - 1)
            if (currentMusicIndex < noOfTrack) {
                setSelectedTrack(tracks[currentMusicIndex + 1])
                //  setPlayedList([...playedList, tracks[currentMusicIndex + 1]])
            }
        } else {

        }

    }

    const playledFn = (ind) => {
        const index = playedList.findIndex(object => {
            return object.id === myPlaylist[ind].id
        });
        if (index === -1) {
            playedList.push(myPlaylist[ind])
        }
        if(playedList.length === noOfTrack){
            setPlayedList([])
                setMyPlaylist([])
                setCurrentMusicIndex(0)
                fetchChildMoods()
                return 0
        }

    }
    const handleClickNext = (e) => {
        try {
            console.log("currentMusicIndex-->", currentMusicIndex, playedList.length," === ", noOfTrack)
            if (playedList.length === noOfTrack && e.type === "ended") {
                setPlayedList([])
                setMyPlaylist([])
                setCurrentMusicIndex(0)
                fetchChildMoods()
                return 0
            } else if (playedList.length === noOfTrack) {
                console.log(playedList)
                setPlayedList([])
                setMyPlaylist([])
                setCurrentMusicIndex(0)
                fetchChildMoods()
                return 0
            }

            if (currentMusicIndex < noOfTrack) {
                setCurrentMusicIndex(currentMusicIndex < myPlaylist.length - 1 ? currentMusicIndex + 1 : 0)
                playledFn(currentMusicIndex)
            }

        } catch (error) {
            console.log("error", error)
        }
    }


    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    useEffect(() => {
        const hash = window.location.hash
        let token = window.localStorage.getItem("token")
        if (!token && hash) {
            token = hash.substring(1).split("&").find(elem => elem.startsWith("access_token")).split("=")[1]

            window.location.hash = ""
            window.localStorage.setItem("token", token)
        }

        // setToken(token)
        createSession()
        fetchToken()
    }, [])
    let rem = 0;
    useEffect(() => {

        const interval = setInterval(async () => {
            if (bgImages.length > 0) {
                const randomnumber = Math.floor(Math.random() * (bgImages.length - 0)) + 0;
                console.log(randomnumber)
                setBgImage(`https://app.feelyoumusic.com/media/backgrounds/${bgImages[randomnumber]?.imageName}`)
            }
            let temp = JSON.parse(JSON.stringify(selectedSlider))
            let count = temp + 1;
            if (count < slides.length) {
                setSelectedSlider(count)
            } else {
                setSelectedSlider(0)
            }
        }, 30000);
        return () => clearInterval(interval);
    }, [bgImages, selectedSlider]);


    useEffect(() => {

        if (playedList?.length > noOfTrack) {
            setPlayedList([])
            fetchChildMoods()
        }
    }, [playedList])

    const fetchChildMoods = async () => {
        try {
            // /moods/getActiveMoodsByMoodIds
            const userData = localStorage.getItem("user");
            if (userData) {
                const user = JSON.parse(userData);
                const splited = location.pathname.split('/')
                const URL = `/moods/PCActiveMoodsByMoodId?moodId=${splited[2]}`;//splited[2] //splited[3]

                const response = await axiosInstance.get(URL, {
                    headers: {
                        Authorization: "Bearer " + user.jwtToken,
                    },
                });
                if (response.status === 200) {
                    setMoodList(response.data)

                }
                const URLNG = `/moods/NCActiveMoodsByMoodId?moodId=${splited[3]}`;//splited[2] //splited[3]

                const response1 = await axiosInstance.get(URLNG, {
                    headers: {
                        Authorization: "Bearer " + user.jwtToken,
                    },
                });

                if (response1.status === 200) {
                    setMoodList([...response.data, ...response1.data])
                    moodhandleOpen()
                }
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const moodHandler = (item) => () => {
        setSelectedMood(item); //add item
        return 0
    }

    const fetchToken = async () => {
        try {
            // const checker = await checkIsValidToken()
            setLoading(true);
            const userData = localStorage.getItem("user");
            if (userData) {
                const user = JSON.parse(userData);
                const splited = location.pathname.split('/')
                const URL = "/spotify/getSpotifyToken";

                const response = await axiosInstance.get(URL, {
                    headers: {
                        Authorization: "Bearer " + user.jwtToken,
                    },
                });
                if (response.status === 200) {
                    //setToken(response.data?.[0].token)
                    setToken(localStorage.getItem('access_token'))
                    return 0
                }
                if (response.status === 401) {
                    history.push(`/login`);
                    return 0
                }
                openSnackbar("Please select a mood");

            }
            setLoading(false);
        } catch (error) {

            if (error.response.status === 401) {
                history.push(`/login`);
                return 0
            }
        };
    }



    const getSpotifyTracks = async (trackIds) => {
        try {
            const pre = await axios.get("https://api.spotify.com/v1/tracks?ids=" + trackIds.toString(), {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': ' application/json',
                    'Authorization': "Bearer " + token
                }
            })
            return await pre?.data.tracks;
        } catch (error) {

            if (error.response.status === 401)
                history.push(`/login`);
            return 0
        }
    }

    const createSession = async () => {
        try {
            // const checker = await checkIsValidToken()
            setLoading(true);
            const userData = localStorage.getItem("user");
            if (userData) {
                const user = JSON.parse(userData);
                const splited = location.pathname.split('/')
                const URL = "/session/addSession";


                const request = {
                    "userId": user.id,
                    "fromMood": splited[3], //1
                    "toMood": splited[2], //1
                    "genres": user.genres
                }
                const response = await axiosInstance.post(URL, request, {
                    headers: {
                        Authorization: "Bearer " + user.jwtToken,
                    },
                });

                const phraseResponse = await axiosInstance.get(`${PHRASE_URL}${splited[2]}`, {
                    headers: {
                        Authorization: "Bearer " + user.jwtToken,
                    },
                });
                const backgroundResponse = await axiosInstance.get(IMAGEURL, {
                    headers: {
                        Authorization: "Bearer " + user.jwtToken,
                    },
                });
                const response_status = await axiosInstance.get(`${MOOD_STATUS_API}${sessionId}`, {
                    headers: {
                        Authorization: "Bearer " + user.jwtToken,
                    },
                });
                if (phraseResponse.status === 200) {
                    setSlideLength(phraseResponse.data.length);
                    setSlides(phraseResponse.data)
                }
                if (response_status.status === 200) {

                    setMoodStatus(response_status.data?.[0]?.totalPoint ? response_status.data?.[0]?.totalPoint : 0)
                }
                if (backgroundResponse.status === 200) {
                    setBgImages(backgroundResponse.data)
                }

                if (response.status === 200) {
                    setTracks(response?.data?.[0]?.tracks);
                    setIterationNo(response?.data?.[0]?.iterationNo)
                    setIterationId(response?.data?.[0]?.iterationId)
                    setSessionId(response?.data?.[0]?.sessionId)
                    setSelectedTrack(response?.data?.[0]?.tracks[0])
                    setLoading(false);
                    setPlayedList([...playedList, response?.data?.[0]?.tracks[0]])
                    let dataArray = [];
                    let obj = { "iteration_no": response?.data?.[0]?.iterationNo };
                    let itData = response?.data?.[0]?.tracks;
                    obj.tracks = itData;
                    dataArray.push(obj)
                    localStorage.setItem("sessionId", response?.data?.[0]?.sessionId);
                    localStorage.setItem("iterationNo", response?.data?.[0]?.iterationNo);

                    localStorage.setItem("iterationData", JSON.stringify(dataArray));
                    return 0
                }
                if (response.status === 401) {
                    history.push(`/login`);
                    return 0
                }
                openSnackbar("Please select a mood");

            }
            setLoading(false);
        } catch (error) {
console.log(error)
            if (error.response.status === 401)
                history.push(`/login`);
            return 0
        }
    };

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const submitType = async () => {
        setIsPlay(!isPlay)
        handleClose();
        history.push(`/NagativeMood`);
    }
    const submitChildMood = async () => {
        try {
            if (selectedMood) {
                setSelectedMood(null)
                if (tracks.length === 0) {
                    setIsPlay(!isPlay)
                    history.push(`/login`);
                }

                if (userData) {
                    let tarckIds=[]
                    let iterationData = [];
                        iterationData = JSON.parse(localStorage.getItem("iterationData"));
                        iterationData.map((data)=>{
                            data.tracks.map((ids)=>{
                                tarckIds.push(ids.id)
                            })
                        })
                    const splited = location.pathname.split('/')
                    const URL = "/iteration/iterationFeedback";
                    const request = {
                        "sessionId": sessionId,
                        "iteration_no": iterationNo,
                        "iterationMoodFeedbackId": selectedMood.id,
                        "iterationPoints": selectedMood.points,
                        "genres": user.genres,
                        "playedIds": tarckIds
                    }
                    //MOOD_STATUS_API
                    const response = await axiosInstance.put(URL, request, {
                        headers: {
                            Authorization: "Bearer " + user.jwtToken,
                        },
                    });


                    const response_status = await axiosInstance.get(`${MOOD_STATUS_API}${sessionId}`, {
                        headers: {
                            Authorization: "Bearer " + user.jwtToken,
                        },
                    });
                    if (response_status.status === 200) {
                        setMoodStatus(response_status.data?.[0]?.totalPoint ? response_status.data?.[0]?.totalPoint : 0)
                    }
                    if (response.status === 200) {
                        setTracks(response?.data?.[0]?.tracks);
                        setIterationNo(response?.data?.[0]?.iterationNo)
                        setIterationId(response?.data?.[0]?.iterationId)
                        setSessionId(response?.data?.[0]?.sessionId)
                        setSelectedTrack(response?.data?.[0]?.tracks[0])
                        localStorage.setItem("iterationNo", response?.data?.[0]?.iterationNo);
                        let obj = { "iteration_no": response?.data?.[0]?.iterationNo };
                        let iterationData = [];
                        iterationData = JSON.parse(localStorage.getItem("iterationData"));
                        let itData = response?.data?.[0]?.tracks;
                        obj.tracks = itData;
                        iterationData.push(obj)
                        localStorage.setItem("iterationData", JSON.stringify(iterationData));
                        setLoading(false);
                        moodhandleClose()
                        setPlayedList([...playedList, response?.data?.[0]?.tracks[0]])
                        setCurrentMusicIndex(0);
                        const phraseResponse = await axiosInstance.get(`${PHRASE_URL}${splited[2]}`, {
                            headers: {
                                Authorization: "Bearer " + user.jwtToken,
                            },
                        });
                        const backgroundResponse = await axiosInstance.get(IMAGEURL, {
                            headers: {
                                Authorization: "Bearer " + user.jwtToken,
                            },
                        });
                        if (backgroundResponse.status === 200) {
                            setBgImages(backgroundResponse.data)
                        }
                        if (phraseResponse.status === 200) {
                            setSlides(phraseResponse.data)
                        }


                    }
                }
            } else {
                if (tracks.length === 0) {
                    setIsPlay(!isPlay)
                    history.push(`/login`);
                } else {
                    openSnackbar("Please select a mood");
                }

            }

        } catch (error) {
            setTracks([])
            setMoodList([])
            if (error.response.data.message == "You reached your destination") {
                moodhandleClose();
                setMoodStatus(100);
                setTimeout(function () {
                    handleOpen();
                }, 2000);

            }
            console.log({ error });
        }
    }
    // useEffect(() => {
    //     debugger;
    //     //submitChildMood();
    // }, [user.genres])
    
    const handleNext = () => {
        const currentIndex = tracks.indexOf(selectedTrack);
        if (currentIndex > 0) {
            setSelectedTrack(tracks[currentIndex - 1])
            setPlayedList([...playedList, tracks[currentIndex - 1]])
        }
    }


    const handlePrev = () => {
        const currentIndex = tracks.indexOf(selectedTrack);
        if (playedList.length === 5) {
            setPlayedList([])
            setSelectedTrack(null)
            fetchChildMoods()
            return 0
        }
        if (currentIndex < 5) {
            setSelectedTrack(tracks[currentIndex + 1])
            setPlayedList([...playedList, tracks[currentIndex + 1]])
        }
    }



    return (
        <div className={classes.back1} style={{
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${bgImage || Image})`,
            boxShadow: 'inset 0 0 0 2000px #532b6373'
        }}>
            <div className={classes.root}>
                <div style={{ height: '100vh' }} className={classes.root}>
                    <div style={{
                        position: 'absolute',
                        top: 30, left: 30
                    }}>
                        <IconButton aria-label="delete" onClick={toggleDrawer("left", true)} sx={{zIndex: '111'}}>
                            <Typography variant="p" style={{ color: '#fff', }}  >
                                <MenuIcon style={{ fontSize: 25 }} />
                            </Typography>
                        </IconButton>
                    </div>
                    <div style={{
                        width: '100%',
                        height: 70,
                        display: 'flex',
                        alignSelf: 'center',
                        justifyContent: 'space-between',
                        position: 'sticky',
                        marginTop: 30
                    }}>


                        <div className={classes.blockMenu}>

                        </div>
                        <div className={classes.blockMenuCenter}>
                            <Logo height={29} width={140} />
                        </div>
                        <div className={classes.blockMenu}>
                            {/* <Logo height={29} width={140} /> */}
                        </div>

                    </div>
                    <div className={classes.middleBox} >

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',

                        }}>
                            {(
                                <Box component="div" className={classes.rootSlide}>

                                    {slides?.map((child, index) => {

                                        if (index === selectedSlider) {
                                            return (
                                                <Box component="div" className={classes.slideBox}>
                                                    <div className={classes.slideBoxDiv} >
                                                        <Typography variant="p" className={classes.semiBoldActive} >
                                                            {slides[selectedSlider].phrase}
                                                        </Typography>
                                                        <Typography className={classes.by} >
                                                            {slides[selectedSlider].phraseBy}
                                                        </Typography>
                                                    </div>
                                                </Box>
                                            )
                                        }
                                        return null
                                    })}

                                    {/* <ul className="dots" >
                                        {slides?.map((child, index) => (
                                            <li className={index === selectedSlider ? 'active' : ''}>
                                                <button onClick={() => {
                                                    //
                                                }}>{index + 1}</button>
                                            </li>
                                        ))}
                                    </ul> */}
                                </Box>)}

                        </div>

                        {/* <a href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&scope=streaming%20user-read-email%20user-read-private%20user-read-playback-state&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}`}>Login
                            to Spotify</a> */}
                        {/* <div style={{ width: '35%', position: "fixed", bottom: isTrack ? '280px' : '26%' }}> */}
                        <div className={classes.progDiv} >
                            <Typography className={classes.by} >
                                Mood Status
                            </Typography>
                            <ProgressBar
                                completed={moodStatus}
                                height={15}
                                labelAlignment="right"
                                bgColor="rgb(152, 54, 250)"
                                maxCompleted={100}
                                labelSize={10}
                                isLabelVisible={true}
                                labelColor="#ffffff"
                            />
                        </div>


                        {
                            loading ? (<p>Loading</p>) : (
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    overflow: 'auto',

                                }}>

                                    <Grid container className={classes.playerGridContainer} >

                                        <Grid item lg={12} sm={12} xs={12} className={classes.playerGridItem}>
                                            {/* <div className={`flex`}>
                                                <img src={spotifyImg} className='top-position smImg' />

                                            </div> */}
                                            <div>
                                                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center' }}>
                                                    {
                                                        myPlaylist.length > 0 && (
                                                            <AudioPlayer
                                                                className={classes.myPlayer}
                                                                header={
                                                                    <div >
                                                                        {myPlaylist.length > 0 && myPlaylist[currentMusicIndex].image !== '' ? (
                                                                            <Grid container spacing={2}>
                                                                                <Grid item xs={1}>

                                                                                    <img src={myPlaylist[currentMusicIndex].image} className={classes.playerGridItemDivImg} />

                                                                                </Grid>
                                                                                <Grid item xs={8}>

                                                                                    <h6 className="title pl-5">
                                                                                        <div className={classes.titleh6}>
                                                                                            {myPlaylist[currentMusicIndex].name?.length > 25 ? myPlaylist[currentMusicIndex].name?.substring(0, 25) + '...' : myPlaylist[currentMusicIndex].name}
                                                                                        </div>
                                                                                    </h6>
                                                                                    <h6 className="pl-5" style={{ fontSize: 16, color: '#333' }}>
                                                                                        <div className={classes.titleh6sec}>
                                                                                            {myPlaylist[currentMusicIndex].artists?.[0]?.name}
                                                                                        </div>
                                                                                    </h6>

                                                                                </Grid>
                                                                                <Grid item xs={1}>

                                                                                </Grid>
                                                                                <Grid item xs={2}>
                                                                                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end' }}>
                                                                                        {like && (
                                                                                            <IconButton aria-label="delete" onClick={setLikeFn}>
                                                                                                <FavoriteIcon style={{ fontSize: 25, color: "#F951D4" }} />
                                                                                            </IconButton>
                                                                                        )}
                                                                                        {!like && (
                                                                                            <IconButton aria-label="delete" onClick={setLikeFn}>
                                                                                                <FavoriteBorderIcon style={{ fontSize: 25 }} />
                                                                                            </IconButton>
                                                                                        )}

                                                                                        {/* <IconButton aria-label="delete" onClick={() => {
                                                                                            setIsTack(!isTrack)
                                                                                        }}>
                                                                                            <SubjectIcon style={{ fontSize: 20, color: isTrack ? "#F951D4" : '' }} />
                                                                                        </IconButton> */}
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        ) : null}
                                                                    </div>
                                                                }
                                                                customProgressBarSection={[

                                                                    RHAP_UI.CURRENT_TIME, RHAP_UI.PROGRESS_BAR, RHAP_UI.DURATION,


                                                                ]}
                                                                customControlsSection={[RHAP_UI.ADDITIONAL_CONTROLS, RHAP_UI.MAIN_CONTROLS, RHAP_UI.VOLUME_CONTROLS,]}
                                                                style={{
                                                                    boxShadow: 'none'
                                                                }}

                                                                onEnded={(e) => {
                                                                    handleClickNext(e)
                                                                    let type = e.type == "click" ? "Next" : "Endon";
                                                                    let fav = like ? ", Like" : "";
                                                                    let rep = repeat ? ", Repeat" : "";
                                                                    getFeedBack(type + fav + rep);
                                                                    setLike(false)


                                                                }}
                                                                autoPlayAfterSrcChange={true}
                                                                showSkipControls={true}
                                                                showJumpControls={false}
                                                                src={myPlaylist[currentMusicIndex].src}
                                                                onClickPrevious={
                                                                    (e) => {
                                                                        handleClickPrevious(e)
                                                                        let type = e.type == "click" ? "Next" : "Endon"
                                                                        let fav = like ? ", Like" : ""
                                                                        let rep = repeat ? ", Repeat" : "";
                                                                        getFeedBack(type + fav + rep);
                                                                        setLike(false);
                                                                    }
                                                                }
                                                                onClickNext={(e) => {
                                                                    handleClickNext(e)
                                                                    let type = e.type == "click" ? "Next" : "Endon"
                                                                    let fav = like ? ", Like" : ""
                                                                    let rep = repeat ? ", Repeat" : "";
                                                                    getFeedBack(type + fav + rep);
                                                                    setLike(false)
                                                                }}
                                                                onListen={(e) => {
                                                                    let secArray = (e.target.currentTime + "").split('.');
                                                                    let time = secArray[0] + (Math.floor(secArray[1] / 1000));
                                                                    setCurrentTime(time)
                                                                }}
                                                                autoPlay
                                                                volume="0.6"
                                                                preload="none"
                                                                customIcons={{
                                                                    loop: <LoopIcon style={{ fontSize: 25, color: "#F951D4" }} onClick={setRepeatFn} />,
                                                                    loopOff: <LoopIcon style={{ fontSize: 25 }} onClick={setRepeatFn} />,
                                                                    play:<PlayCircleFilledWhiteIcon style={{ fontSize: 40,color: "#F951D4" }}/>,
                                                                    volumeMute: <VolumeOffIcon style={{ fontSize: 25, color: "#F951D4" }}/>,
                                                                    volume:<VolumeUpIcon style={{ fontSize: 25 }}/>
                                                                }

                                                                }
                                                            />
                                                        )}
                                                </div>
                                            </div>
                                        </Grid>

                                        <Grid container>
                                            {
                                                isTrack && (
                                                    <Grid style={{
                                                        overflow: 'auto',
                                                        padding: 10, backgroundColor: '#fff', width: '100%',
                                                        borderBottomLeftRadius: 8,
                                                        borderBottomRightRadius: 8
                                                    }}>


                                                        {
                                                            myPlaylist?.map((child, index) => {
                                                                return (
                                                                    <Grid

                                                                        className={classes.trackList}
                                                                        style={{

                                                                            backgroundColor: currentMusicIndex === index ? '#F951D412' : 'transparent',
                                                                        }}
                                                                        key={`child${index}`}
                                                                        onClick={() => {
                                                                            setCurrentMusicIndex(index)
                                                                            playledFn(index)

                                                                        }}
                                                                    >
                                                                        <IconButton aria-label="delete">
                                                                            {currentMusicIndex === index ? (<PauseIcon style={{ fontSize: 20 }} />) : (<PlayArrowOutlinedIcon style={{ fontSize: 20 }} />)}
                                                                            <p style={{
                                                                                fontSize: 14,
                                                                                fontWeight: '400',
                                                                                fontFamily: 'Roboto',
                                                                               
                                                                                color: 'rgba(4, 4, 4, 0.8)'
                                                                            }}>{child.name}</p>
                                                                        </IconButton>

                                                                    </Grid>
                                                                )
                                                            })
                                                        }
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                </div>
                            )
                        }

                    </div>
                </div>

                <Model open={open}
                    handleClose={handleClose}
                    classes={classes}
                    submitType={submitType}
                    submitChildMood={submitChildMood}
                />
                <MoodModel open={openMood}
                    handleClose={moodhandleClose}
                    classes={classes}
                    moodList={moodList}
                    selectedMood={selectedMood}
                    moodHandler={moodHandler}
                    submitChildMood={submitChildMood}
                    submitType={submitType}
                />
                <FirstModel open={openFirst}
                    handleClose={firsthandleClose}
                    classes={classes}
                    submitType={submitType}
                />
                <Drawer toggleDrawer={toggleDrawer} state={state} />
            </div>
        </div >
    )
}

const useStyles = makeStyles((theme) => ({
    myPlayer: {
        padding: '0px',
        '& .rhap_main-controls-button': {
            color: 'rgba(4, 4, 4, 0.4)'
        },
        '& .rhap_progress-filled': {

            backgroundColor: '#F951D4'
        }
    },
    threeButtonW: {
        [theme.breakpoints.up('lg')]: {

        },
        [theme.breakpoints.down('sm')]: {

            display: 'none'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'

        },
    },
    threeButtonM: {
        width: '33%',
        display: 'none',
        [theme.breakpoints.up('lg')]: {

        },
        [theme.breakpoints.down('sm')]: {

            display: 'block'
        },
        [theme.breakpoints.down('xs')]: {
            display: 'block'

        },
    },
    trackList: {
        height: 28, flexDirection: 'row',
        display: 'flex', alignItems: 'center',
        cursor: 'pointer',
        [theme.breakpoints.up('lg')]: {

        },
        [theme.breakpoints.down('sm')]: {
            height: 24

        },
        [theme.breakpoints.down('xs')]: {
            height: 24

        },
    },
    progDiv:{
        width: '35%',
        position: "fixed", 
        bottom: '28%',
        [theme.breakpoints.up('lg')]: {

        },
        [theme.breakpoints.down('sm')]: {
            width: '80%',
          

        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            
        },
    },
    middleBox: {
        display: 'flex',
        flexDirection: 'column',
        height: '300px',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
        padding: '142px 0',

        [theme.breakpoints.up('lg')]: {

        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            lineHeight: '15px',

        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
            lineHeight: '15px',

        },
    },
    titleh6: {
        textAlign: 'left',
        [theme.breakpoints.up('lg')]: {

        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            lineHeight: '15px',
            marginLeft: '18px'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
            lineHeight: '15px',
            marginLeft: '18px'
        },
    },
    titleh6sec: {
        textAlign: 'left',
        [theme.breakpoints.up('lg')]: {

        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
            lineHeight: '15px',
            marginLeft: '18px'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
            lineHeight: '15px',
            marginLeft: '18px'
        },
    },
    mR: {
        marginRight: 20
    },
    playerGridContainer: {
        width: '45%',
        padding: 10,
        justifyContent: 'space-between',
        backgroundColor: '#fff',
        position: 'absolute',
        bottom: "2%",
        flexDirection: 'row', display: 'flex',
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 10,
        boxShadow: "12px 12px 2px 1px rgb(0 0 0 / 15%)",
        [theme.breakpoints.up('lg')]: {

        },
        [theme.breakpoints.down('sm')]: {
            width: '95%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%'
        },
    },
    playerGridItem: {
        width: '33%',
        [theme.breakpoints.up('lg')]: {

        },
        [theme.breakpoints.down('sm')]: {
            width: '54%'
        },
        [theme.breakpoints.down('xs')]: {
            width: '54%'
        },
    },
    playerGridItemDiv: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center'
    },
    playerGridItemDivImg: {
        height: 50, width: 50,
        [theme.breakpoints.up('lg')]: {

        },
        [theme.breakpoints.down('sm')]: {
            height: 40, width: 40,
        },
        [theme.breakpoints.down('xs')]: {
            height: 40, width: 40,
        },
    },
    slideBox: {
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    slideBoxDiv: {
        textAlign: 'center',
        width: '50%',
        [theme.breakpoints.up('lg')]: {

        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
    },
    root: {
        paddingLeft: 100,
        paddingRight: 100,
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'space-around',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    headerStyle: {
        height: '100px',
        display: 'flex',
        alignItems: 'flex-end',
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            alignItems: 'flex-end',
            paddingTop: 15,
            height: '10%'
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'flex-start',
            padding: 15,
            height: '10%'
        },
    },
    center: {
        height: '73%',
        display: 'flex',
        alignItems: 'center',
        // backgroundColor: 'red',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'flex-start',
            padding: 15,
            height: '60%',
        },
    },
    footer: {
        height: '15%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'flex-start',
            height: '20%',
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    blockMenu: {
        width: 270,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    blockMenuCenter: {
        width: 270,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },

    modelBox: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        borderRadius: '12px',
        // width: 400,
        padding: 10,
        // bgcolor: 'background.paper',
        // border: '2px solid #000',
        // boxShadow: 24,
        // p: 4,
        [theme.breakpoints.up('lg')]: {

        },
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
    },
    root: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        // position:'absolute',
        // left: 0,
        // top: 0,
        // background: 'linear-gradient(180deg, #53168F 0%, rgba(0, 0, 0, 0.5) 100%)',
        // backgroundImage: `url(${Linear})`,
        backgroundSize: 'contain',
        // backgroundPosition: 'center',
        // opacity: 0.5,
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'right center',
        },
    },
    back1: {
        width: '100%',
        height: '100%',
        backgroundImage: `url(${Image})`,

        backgroundSize: 'cover',
        backgroundPosition: 'center',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('md')]: {

            backgroundSize: 'cover',
            backgroundPosition: '85% 50%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: '85% 50%',
        },
    },
    grid3: {

        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            alignItems: 'center'
        },
    },
    by: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: ' 16px',
        lineHeight: ' 21px',
        marginBottom: 10,
        textAlign: 'center',

        color: '#FFFCFC',
        // width: '100%',
        opacity: 1,
        transition: 'all 600ms ease',
        transitionDelay: '300ms',
        [theme.breakpoints.down('md')]: {

            lineHeight: ' 28px',
        },
        [theme.breakpoints.up('sm')]: {

            lineHeight: ' 28px',
            fontSize: ' 18px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: ' 18px',
            lineHeight: ' 28px',
            textAlign: 'center'
        },

    },
    rootSlide: {

        // paddingTop:253,
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        transition: 'all 600ms cubic-bezier(0.645, 0.045, 0.355, 1)',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        },
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            marginTop: 0,

        },
    },
    bold: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 20

    },
    semiBold: {
        // fontFamily: ' Publico Headline',
        fontFamily: 'Circular Std',
        fontStyle: 'italic',
        fontWeight: '900',
        fontSize: '62px',
        lineHeight: '61px',
        opacity: 0,
        transition: 'all 600ms ease',
        transitionDelay: '300ms',
        color: '#FFFCFC',
        marginBottom: 15,
        [theme.breakpoints.down('md')]: {
            marginTop: 0,
            width: '90%',
            fontSize: 35,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
            width: '100%',
            fontSize: 49,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            width: '100%',
            lineHeight: '27px',
            fontSize: 25,
            textAlign: 'center'
        },
    },
    semiBoldActive: {
        // fontFamily: ' Publico Headline',
        fontFamily: 'Roboto',
        fontWeight: '500',
        fontSize: '32px',
        lineHeight: '37.5px',
        opacity: 1,
        transition: 'all 600ms ease',
        transitionDelay: '300ms',
        color: '#FFFCFC',
        marginBottom: 15,
        [theme.breakpoints.down('md')]: {
            fontSize: '32px',
        },
        [theme.breakpoints.up('sm')]: {

        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '20px',
            lineHeight: '28.5px',
        },

    },

    boxLink: {
        cursor: 'pointer'
    },
    boxText: {
        cursor: 'default'
    },
    activeDot: {
        color: '#fff',
        marginRight: 40,
        height: 10,
        width: 10,
    },
    dot: {
        color: 'rgba(255,255,255,0.5)',
        cursor: 'pointer',
        marginRight: 40,
        height: 12,
        width: 12,
    },
    dotContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 15,
        [theme.breakpoints.down('sm')]: {
            marginTop: 5,
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 10,
        },

    },
    smImg: {
        width: '4rem'
    },
    link: {
        color: '#9738f6 !important',
        fontWeight: '400 !important'
      },

}))

