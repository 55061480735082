/**
 * Trace confirmation file
 */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { motion } from "framer-motion";
import { Wrapper } from "../../../components/Wrapper";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { ProfileModal } from "../../app/home/ProfileModal";


export const TrackConfirmation = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const nextHandler = () => {
    localStorage.setItem("sessionId", 0);
    history.push("/NagativeMood");
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const logoutHandler = () => {
    history.push("/login");
  };

  useEffect(()=>{
    if(user.genres == ""){
      handleOpen();
   }
  },[])

  return (
    <Wrapper loader={loading}>
       <ProfileModal  open={open}
                    handleClose={handleClose} showCross={false}/>
      <motion.div
        className='flex w-100'
        id='forgot'
        initial={{ opacity: 0, y: `100vh` }}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          type: "spring",
          duration: 1,
        }}
        exit={{ opacity: 0 }}
      >


        <Typography className={classes.by} >
          Feelyou music will be measuring your emotional state to help you get to your desired goal vibe. As you listen, you will be periodically asked if the music playing is helping you obtain your goal vibe. This will instantly update the measurement bar above.
        </Typography>

        <button
          type='submit'
          className='btn-outline text mt-40 pointer'
          onClick={nextHandler}
        >
          Accept
        </button>
        <button
          type='submit'
          className='btn text mt-30 pointer'
          onClick={logoutHandler}
        >
          Logout
        </button>
      </motion.div>
    </Wrapper>
  );
};

export const useStyles = makeStyles((theme) => ({
  by: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontSize: '32px',
    lineHeight: '37.5px !important',
    opacity: 1,
    transition: 'all 600ms ease',
    transitionDelay: '300ms',
    color: '#FFFCFC',
    padding: 75,
    textAlign: "center",
    marginBottom: 15,
    [theme.breakpoints.down('md')]: {
      fontSize: ' 24px',
      lineHeight: ' 28px',

    },
    [theme.breakpoints.up('sm')]: {
      fontSize: ' 24px',
      lineHeight: ' 28px',

    },
    [theme.breakpoints.down('xs')]: {
      fontSize: ' 18px',
      lineHeight: ' 28px',
      textAlign: 'center',
      padding: '50px'
    },

  }

}))