import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { IconButton, Typography, Avatar, Box, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@mui/icons-material/Close";
import { ClassSharp } from "@material-ui/icons";
import axios from "axios";
import Chip from "@mui/material/Chip";
import { motion } from "framer-motion";
import { useSnackbar } from "react-simple-snackbar";

const axiosInstance = axios.create({
  baseURL: "https://api-beta.feelyoumusic.com",
  headers: {
    Accept: "application/json",
  },
});

export const ProfileModal = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const classes = useStyles();
  const [sessionCount, setSessionCount] = useState("");
  const [sessionTime, setSessionTime] = useState("");
  const [genreList, setGenreList] = useState(
    JSON.parse(localStorage.getItem("genres"))
  );
  const [selectedGenre, setSelectedGenre] = useState(
    user.genres ? user.genres.split(',') : []
  );
  const [loading, setLoading] = useState(false);
  const { open, handleClose, showCross=true } = props;
  const [showGenreError, setShowGenreError] = useState(false);
  const [showGenreSuccess, setShowGenreSuccess] = useState(false);
  const [genreAdding, setGenreAdding] = useState(false);

  const sessionCountFn = async () => {
    const URL = `/session/getSessionSumByUserId?userId=${user.id}`;

    const response = await axiosInstance.get(URL, {
      headers: {
        Authorization: "Bearer " + user.jwtToken,
      },
    });
    if (response.status === 200) {
      setSessionCount(response.data[0].sessionCount);
    }
  };

  const sessionTimeFn = async () => {
    const URL = `/iterationAnalytics/getSessionTimeByuserId?userId=${user.id}`;

    const response = await axiosInstance.get(URL, {
      headers: {
        Authorization: "Bearer " + user.jwtToken,
      },
    });
    if (response.status === 200) {
      let time = response.data[0].timeSum.split(":");
      // Hours are worth 60 minutes.
      var minutes = +time[0] * 60 + +time[1];
      setSessionTime(minutes);
    }
  };
  useEffect(() => {
    if (!sessionTime) {
      sessionTimeFn();
    }
  }, [sessionTime]);

  useEffect(() => {
    if (!sessionCount) {
      sessionCountFn();
    }
  }, [sessionCount]);

  const genreHandler = (genre) => {
    const list = [...selectedGenre];
    const itemIndexEmp = list.findIndex((item) => item === "");
    if (itemIndexEmp > -1) {
      list.splice(itemIndexEmp, 1);
    }
    const itemIndex = list.findIndex((item) => item === genre);
    if (itemIndex > -1) {
      list.splice(itemIndex, 1);
    } else {
      list.push(genre);
    }
    setSelectedGenre(list);
  };
  const submitHandler = async () =>{
    try {
        if (selectedGenre.length === 0) {
            setShowGenreError(true);
            return false;
          }
          setGenreAdding(true);
          const data = {
              genres: selectedGenre.toString()
            };
            const URL = "/accounts/"+user.id;
            const response = await axiosInstance.put(URL, data, {
              headers: {
                Authorization: "Bearer " + user.jwtToken,
              },
            });
            if(response.data){
                let newData = user;
                newData.genres = response.data.genres;
                localStorage.setItem("user", JSON.stringify(newData)); 
                setShowGenreSuccess(true); 
                setGenreAdding(false);
                handleClose();
            }
    } catch (error) {
        console.error("GenreError", error);
    }
  };
  return (
    <Modal
      open={open}
      onClose={(_, reason) => {
        if(showCross){
            if (reason !== "backdropClick") {
                handleClose();
                 }
        }else{
            handleClose();
        }
         
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ borderRadius: "8px" }}
    >


      {!loading &&  (
        <Box className={classes.modelBox}>
          <Grid
            container
            direction="row"
            justify="space-between"
            style={{ color: "#fff" }}
          >
            <Grid item xs={11}>
              <Typography variant="h4">Profile</Typography>
            </Grid>
            <Grid item xs={1}>
                {showCross && (
                    <IconButton
                aria-label="close"
                onClick={handleClose}
                className={classes.ico}
              >
                <CloseIcon fontSize="large" />
              </IconButton>
                )}
              
            </Grid>
          </Grid>
          <div className="flex w-100">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Grid container spacing={1} className={classes.mainContainer}>
                <Grid item xs={1} className={classes.propicText}>
                  <Avatar
                    alt={user.name}
                    src="/static/images/avatar/1.jpg"
                    className={classes.propic}
                  />
                </Grid>
                <Grid item xs={11} className={classes.desc}>
                  <Grid item xs={12}>
                    <div className={classes.descName}>
                      <Typography variant="h1" className={classes.titleName}>
                        {user.name}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    rowSpacing={1}
                    style={{ textAlign: "center" }}
                  >
                    <Grid item xs={6}>
                      <Typography
                        variant="h4"
                        style={{ color: "#786283", paddingTop: 20 }}
                      >
                        Feelyou Sessions
                      </Typography>
                      <Typography
                        variant="h1"
                        style={{ color: "#786283", paddingTop: 20 }}
                      >
                        {sessionCount}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        variant="h4"
                        style={{ color: "#786283", paddingTop: 20 }}
                      >
                        Feelyou Minutes
                      </Typography>
                      <Typography
                        variant="h1"
                        style={{ color: "#786283", paddingTop: 20 }}
                      >
                        {sessionTime}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ textAlign: "left", paddingLeft: "30px" }}
                  >
                    <Typography
                      variant="h4"
                      style={{ color: "#786283", paddingTop: 20 }}
                    >
                      What do you like listening to? 
                    </Typography>
                    <div className="row mt-10">
                      {showGenreError && (
                        <Typography
                          variant="h5"
                          style={{ color: "red", padding: "4px" }}
                        >
                          * Please select genre.
                        </Typography>
                      )}
                      {showGenreSuccess && (
                        <Typography
                          variant="h5"
                          style={{ color: "green", padding: "4px" }}
                        >
                          Successfully genres added !!
                        </Typography>
                      )}
                      {genreList.map((genre) => {
                        return (
                          <Chip
                            color="secondary"
                            label={genre.genre}
                            variant={
                              selectedGenre.findIndex(
                                (item) => item === genre.genre
                              ) === -1
                                ? "outlined"
                                : ""
                            }
                            classes={classes.myChip}
                            key={genre.id}
                            onClick={() => {
                              genreHandler(genre.genre);
                            }}
                          />
                        );
                      })}
                    </div>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: "right" }}>
                    <motion.button
                      type="submit"
                      className="btn-outline text mt-40 pointer"
                      onClick={submitHandler}
                      style={{ width: "10rem", height: "4rem" }}
                    >
                      Save
                    </motion.button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      )}
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  modelBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#f178b6",
    borderRadius: "12px",
    padding: 10,
    width: "65%",
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  mainContainer: {
    margin: "30px",
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("sm")]: {
      margin: "4px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "4px",
    },
    "& .MuiChip-root": {
      marginLeft: "4px !important",
      marginBottom: "4px",
      fontSize: "1.8rem !important",
      fontWeight: "400 !important",
      fontFamily: "Roboto !important",
      borderRadius: "10px !important",
      background:
        "linear-gradient(69.5deg, rgba(189, 73, 255, 0.99) 18.6%, rgb(254, 76, 227) 85.9%) !important",
      height: "26px !important",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px !important",
      },
    },
    "& .MuiChip-outlinedSecondary": {
      color: "#a39d9d !important",
      background: "#F8F8F8 !important",
      border: "none !important",
    },
    "& .MuiChip-label": {
      fontSize: "1.8rem !important",
      fontWeight: "400 !important",
      fontFamily: "Roboto !important",
    },
  },
  desc: {
    paddingLeft: "38px !important",
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "58px !important",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "58px !important",
    },
  },
  descName: {
    borderBottom: "5px solid #fff",
    marginLeft: "30px",
  },
  titleName: {
    color: "#fff",
    padding: "10px 10px 11px 0px",
    [theme.breakpoints.up("lg")]: {},
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px !important",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "30px !important",
    },
  },
  ico: {
    "& .MuiSvgIcon-root": {
      color: "#fff !important",
    },
    "& .MuiButtonBase-root": {
      color: "#fff !important",
    },
  },
  propicText: {
    "& .MuiAvatar-root": {
      fontSize: "38px !important",
      marginTop: 4,
      border: "4px solid !important",
    },
  },
  propic: {
    width: "100px !important",
    height: "100px !important",
  },
}));
