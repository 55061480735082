/**
 * Negative mood file 
 */
import React, {useState, useEffect} from "react";
import {useHistory} from "react-router";
import axios from "axios";
import {motion} from "framer-motion";
import {useSnackbar} from "react-simple-snackbar";
import {Wrapper} from "../../../components/Wrapper";
import {DoneIcon, RelaxIcon} from "../../../assets/images";

const axiosInstance = axios.create({
  baseURL: "https://api-beta.feelyoumusic.com",
  headers: {
    Accept: "application/json",
  },
});

export const NagativeMood = () => {
  const history = useHistory();
  const [moodList, setMoodList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMood, setSelectedMood] = useState(null);
  const [openSnackbar, closeSnackbar] = useSnackbar();

  useEffect(() => {
    fetchMoods();
  }, []);

  const moodHandler = (item) => () => {
    setSelectedMood(item); //add item
    return 0

  };

  const fetchMoods = async () => {
    try {
      setLoading(true);
      const userData = localStorage.getItem("user");
      if (userData) {
        const user = JSON.parse(userData);
        const URL = "/moods/NPActiveMoods";
        const response = await axiosInstance.get(URL, {
          headers: {
            Authorization: "Bearer " + user.jwtToken,
          },
        });

        if (response.data) {
          setMoodList(response.data);
        }
      }
      setLoading(false);
    } catch (error) {
      if (error.response.status === 401)
          history.push(`/login`);
      console.error("fetchMoodErr", error);
    }
  };

  const nextHandler = () => {
    setLoading(true);
    if (selectedMood) {
      history.push(`/mood/${selectedMood.id}`, {ngMoodId: selectedMood.id});
    } else {
      setTimeout(() => {
        openSnackbar("Please select a mood");
      }, 2500);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <Wrapper loader={loading}>
      <motion.div
        className='flex w-100'
        id='forgot'
        initial={{opacity: 0, y: `100vh`}}
        animate={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          type: "spring",
          duration: 1,
        }}
        exit={{opacity: 0}}
      >
        <h1 className='l-heading mt-30'>1. How are you feeling?</h1>

        {moodList.length > 0 && (
          <div className='lg-input-container mt-30 scroll'  style={{height: 'auto'}}>
            {moodList.length > 0 &&
              moodList.map((item, index) => {
                const isSelected = selectedMood?.id === item.id;

                return (
                  <>
                    <div
                      className={`lg-mood  pointer center  ${isSelected ? "selected-mood" : ""
                        }`}
                      onClick={moodHandler(item)}
                      key={item.id}
                    >
                      <div
                        className='item-center '
                        style={{position: "relative"}}
                      >
                        {
                          item.emoticon ? (
                            <img
                              alt="emoIcon"
                              src={`https://app.feelyoumusic.com/static/media/emoticon/${item.emoticon}`}
                              style={{height: 24, width: 24, marginRight: 5}} />
                          ) : (
                            <RelaxIcon />
                          )
                        }

                        <h2 className='title pl-5'>{item.mood}</h2>
                      </div>
                      {isSelected && (
                        <div style={{position: "absolute", right: "14%"}}>
                          <DoneIcon />
                        </div>
                      )}
                    </div>
                    <div style={{
                      height: 1,
                      backgroundColor: '#DADADA',
                    }}></div>
                  </>
                );
              })}
          </div>
        )}

        <button
          type='submit'
          className='btn-outline text mt-40 pointer'
          onClick={nextHandler}
        >
          Next
        </button>
      </motion.div>
    </Wrapper>
  );
};
